<ng-container *ngIf="data.categoryMenuType==2;">

	<div class="theme-container" style="white-space: normal;" *ngIf="display" >

		<ng-container *ngFor="let menu1 of categoryArray;" >

			<span  *ngIf="data.categoryImageDisplayHomepage==0">

				<span *ngIf="menu1.subcategories.length > 0">
					<button #button 	
					mat-button [matMenuTriggerFor]="panel.menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
						<mat-icon [style.color]="data.categoryColor" >arrow_drop_down</mat-icon>
					</button>			
				</span>
	
				<span *ngIf="menu1.subcategories.length == 0">
					<a mat-button  routerLinkActive="active-link"
						(click)="goToCategory(menu1)">
						
						<span [style.color]="data.categoryColor" class="menuSize theme-container">{{menu1.categoryName | uppercase}}</span>
					</a>
				</span>

			</span>



			<span  *ngIf="data.categoryImageDisplayHomepage==1">
				<span *ngIf="menu1.subcategories.length > 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu1.image" [alt]="menu1.categoryName">
					<button #button 	
					mat-button [matMenuTriggerFor]="panel.menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
						<mat-icon [style.color]="data.categoryColor" >arrow_drop_down</mat-icon>
					</button>
					
				</span>
	
				<span *ngIf="menu1.subcategories.length == 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu1.image" [alt]="menu1.categoryName">
					<a mat-button  routerLinkActive="active-link"
						(click)="goToCategory(menu1)">
						
						<span [style.color]="data.categoryColor" class="menuSize theme-container">{{menu1.categoryName | uppercase}}</span>
					</a>
				</span>

			</span>
		
			
			<span >
<calsoft-menu2  [data]="data" [display]="display" yPosition="below" 
 #panel  [items$]="menu1.subcategories"></calsoft-menu2> 

</span>
</ng-container>
</div>


<div class="backMenu theme-container"  fxHide.lt-md *ngIf="!display">

	<mat-toolbar class="top-navbar theme-container backMenu"[color]="color ? 'primary' : null" 
	
	  fxLayoutAlign="center center">
		<ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">
			<span *ngIf="menu1.subcategories.length > 0">
				<a    class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
					<mat-icon [style.color]="data.categoryColor"  class="caret">arrow_drop_down</mat-icon>
				</a>
			</span>
			<a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
				(click)="goToCategory(menu1)">
				<span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName | uppercase}}</span>
			</a>
			<span (mouseleave)="menuTrigger.closeMenu()"></span>

			<mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu theme-container app-dropdown">

				<div fxLayout="row wrap" fxLayout.xs="column wrap">

					<div [ngClass]="{'cat': menu1.mega != 'false'}">


						<span *ngFor="let submenu1 of menu1.subcategories">

							<a (click)="goToCategory(submenu1)" mat-menu-item
								routerLink="/"><span [style.color]="data.categoryColor">{{submenu1.categoryName}}</span>
			
								<i [style.color]="data.categoryColor" class="material-icons theme-container subMenus">arrow_right</i>
							
							</a>


								<calsoft-nested-child [data]="data" [type]="'ipad'" [list]="submenu1.subcategories"></calsoft-nested-child>

							<!-- <span *ngIf="submenu1.subcategories && submenu1.subcategories.length >0">

								<a *ngFor="let subChild of submenu1.subcategories" (click)="goToCategory(subChild)"
									mat-menu-item routerLink="/"> {{subChild.categoryName}}</a>
							</span> -->


						</span>
					</div>

				</div>

			</mat-menu>
		</ng-container>
	</mat-toolbar>
</div>
		

</ng-container>

<ng-container *ngIf="data.categoryMenuType==3;">
	<nav class="app-nav" style="white-space: normal;" *ngIf="display" >
		<ul ngClass.lt-lg="catNames" class="app-nav-list">
			<li class="app-nav-item" *ngFor="let menu of categoryArray" [ngClass]="{'mega': menu.mega != 'false'}">
				<span *ngIf="data.categoryImageDisplayHomepage==0">
					<ng-container *ngIf="menu.subcategories.length > 0">
						<a  [style.color]="data.categoryColor" (click)="goToCategory(menu)" class="nav-link  pointer" routerLinkActive="active-link">
							<span class="menuborder">{{menu.categoryName }}</span>
						</a>
		
					</ng-container>
		
					<a  [style.color]="data.categoryColor" class="nav-link  pointer" (click)="goToCategory(menu)" *ngIf="menu.subcategories.length == 0">
						<span class="menuborder">{{menu.categoryName }}</span>
					</a>
				</span>
	
				<span *ngIf="data.categoryImageDisplayHomepage==1">
					<div style="display: flex; align-items: center;" *ngIf="menu.subcategories.length > 0">
						<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
						<a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" ><span class="menuborder">{{ menu.categoryName }}</span></a>
					  </div>
		
					
					<div style="display: flex; align-items: center;" *ngIf="menu.subcategories.length == 0">
						<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
						<a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" ><span class="menuborder">{{ menu.categoryName }}</span></a>
					  </div>
				</span>
		
	
				<div class="sub-menu " style="z-index: 1000;padding: 15px;" *ngIf="menu.subcategories && menu.subcategories.length >0 ">
	
					<div [ngClass]="{'cat': menu.mega != 'false'}">
						<div *ngFor="let submenu1 of menu.subcategories" >
	
							<ng-container>
								<div fxLayout="row" style="align-items: baseline;" >
									<span (click)="goToCategory(submenu1)"  class="subMenus ">
										<span >{{submenu1.categoryName }}</span>
									</span>
									<!-- <i [style.color]="data.categoryColor" class="material-icons subMenus">arrow_right</i> -->
								</div>
								<div fxLayout="row" fxLayoutAlign="start center">
									<calsoft-nested-child  [data]="data" [type]="'all'" [list]="submenu1.subcategories"></calsoft-nested-child>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</nav>
</ng-container>

<ng-container  *ngIf="data.categoryMenuType==1;">
<nav class="app-nav" style="white-space: normal;" *ngIf="display" >
	<ul ngClass.lt-lg="catNames" class="app-nav-list">
		<li class="app-nav-item" *ngFor="let menu of categoryArray" [ngClass]="{'mega': menu.mega != 'false'}">
			<span *ngIf="data.categoryImageDisplayHomepage==0">
				<ng-container *ngIf="menu.subcategories.length > 0">
					<a  [style.color]="data.categoryColor" (click)="goToCategory(menu)" class="nav-link  pointer" routerLinkActive="active-link">
						{{menu.categoryName }}
					</a>
	
				</ng-container>
	
				<a  [style.color]="data.categoryColor" class="nav-link  pointer" (click)="goToCategory(menu)" *ngIf="menu.subcategories.length == 0">
					{{menu.categoryName }}
				</a>
			</span>

			<span *ngIf="data.categoryImageDisplayHomepage==1">
				<div style="display: flex; align-items: center;" *ngIf="menu.subcategories.length > 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
					<a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" >{{ menu.categoryName }}</a>
				  </div>
	
				
				<div style="display: flex; align-items: center;" *ngIf="menu.subcategories.length == 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'" [src]="serverPath + menu.image" [alt]="menu.categoryName">
					<a [style.color]="data.categoryColor" class="nav-link pointer" (click)="goToCategory(menu)" >{{ menu.categoryName }}</a>
				  </div>
			</span>
	

			<div class="sub-menu " style="z-index: 1000;" *ngIf="menu.subcategories && menu.subcategories.length >0 ">

				<div [ngClass]="{'cat': menu.mega != 'false'}">
					<div *ngFor="let submenu1 of menu.subcategories">

						<ng-container>
							<div fxLayout="row" style="align-items: baseline;" >
								<span (click)="goToCategory(submenu1)" [style.color]="data.categoryColor" class="subMenus ">
									{{submenu1.categoryName }}
								</span>
								<i [style.color]="data.categoryColor" class="material-icons subMenus">arrow_right</i>
							</div>
							<div fxLayout="row" fxLayoutAlign="start center">
								<calsoft-nested-child  [data]="data" [type]="'all'" [list]="submenu1.subcategories"></calsoft-nested-child>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</li>
	</ul>
</nav>


<div fxHide.lt-md *ngIf="!display">
	<mat-toolbar [color]="color ? 'primary' : null"  class="top-navbar" fxLayoutAlign="center center">
		<ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">
			<span *ngIf="menu1.subcategories.length > 0">
				<a    class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
					(click)="openMegaMenu()"><span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName}}</span>
					<mat-icon [style.color]="data.categoryColor"  >arrow_drop_down</mat-icon>
				</a>
			</span>
			<a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
				(click)="goToCategory(menu1)">
				<span [style.color]="data.categoryColor" class="menuSize">{{menu1.categoryName}}</span>
			</a>
			<span (mouseleave)="menuTrigger.closeMenu()"></span>

			<mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">

				<div fxLayout="row wrap" fxLayout.xs="column wrap">

					<div [ngClass]="{'cat': menu1.mega != 'false'}">


						<span *ngFor="let submenu1 of menu1.subcategories">

							<a (click)="goToCategory(submenu1)" mat-menu-item
								routerLink="/"><span [style.color]="data.categoryColor" >{{submenu1.categoryName}}</span>
			
								<i [style.color]="data.categoryColor" class="material-icons subMenus">arrow_right</i>
							
							</a>


								<calsoft-nested-child [data]="data" [type]="'ipad'" [list]="submenu1.subcategories"></calsoft-nested-child>

							<!-- <span *ngIf="submenu1.subcategories && submenu1.subcategories.length >0">

								<a *ngFor="let subChild of submenu1.subcategories" (click)="goToCategory(subChild)"
									mat-menu-item routerLink="/"> {{subChild.categoryName}}</a>
							</span> -->


						</span>
					</div>

				</div>

			</mat-menu>
		</ng-container>
	</mat-toolbar>
</div>
</ng-container>


import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { MatSidenavModule } from '@angular/material/sidenav';
import { PipesModule } from './theme/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalsoftInterceptor } from './shared/utils/calsoft-interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderService } from './services/loader.service';
import { AuthService } from './services/auth.service';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { menuScrollStrategy } from './shared/utils/scroll-strategy';
import { Overlay } from '@angular/cdk/overlay';
import { SharedModule } from './shared/shared.module';
import { ComponentModule } from './theme/components/component.module';
import { MainComponent } from './pages/main/main.component';
import { BreadcrumbModule } from './theme/breadcrumb/breadcrumb.module';
import { PromptComponent } from './prompt/prompt.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ShareChatTrackingService } from './services/share-chat-tracking.service';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PromptComponent,
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
     HammerModule,
    MatSnackBarModule,
    BreadcrumbModule,
    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatSidenavModule,
    PipesModule,
    MatDialogModule,
    SharedModule,
    ComponentModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ],
  providers: [
    AuthService,
    LoaderService,
    ShareChatTrackingService,
    Title,
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },

    { provide: HTTP_INTERCEPTORS, useClass: CalsoftInterceptor, multi: true },

    //{provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EcryptServiceService } from './ecrypt-service.service';
import { environment } from 'src/environments/environment';
import { AnalyticService, GaActionEnum } from './analytic-service';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';
export const VALUE = 'localeCode'
export const LOCALEINDEX = 'localeCodeIndex'
export const CURRENCY = "currency";
@Injectable({
	providedIn: 'root'
})
export class CommonService {



	constructor(
		private http: HttpClient, private router: Router,
		private ecryptServiceService: EcryptServiceService,
		public googleAnalyticsService: AnalyticService,
		private route: ActivatedRoute,
		//private userService: UserService
	) { }
	url = environment.serverAPI4 + "/api";
	url2 = environment.serverAPI4 + "/api/ig";

	getCountry(): Observable<Object> {
		return this.http.get(`${this.url}/country`);
	}

	getCountryList(): Observable<Object> {
		let authourized = this.getAuthenticatedUser();
		let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
		if(guestLoginEnabled=="0"||authourized){
		return this.http.get(`${this.url}/config/country/all?page=0&size=256`);
		}else{
			return this.http.get(`${this.url2}/config/country/all?page=0&size=256`);
		}
	}

	getAuthenticatedUser() {
		let userName = null;
		if (Constant.DEVICE_TYPE == "MOBILE") {
		  userName = localStorage.getItem("authenticaterUser");
		} else {
		  userName = this.getCookie("user-name");
		}
		return userName;
	  }
	
	  getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(";");
		for (var i = 0; i < ca.length; i++) {
		  var c = ca[i];
		  while (c.charAt(0) == " ") {
			c = c.substring(1);
		  }
		  if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		  }
		}
		return "";
	  }

	saveSearchKeyWords(search: any): Observable<Response[]> {
		return this.http.post<Response[]>(`${this.url}/ig/searchquery`, search);
	}

	productNavigation(product: any) {

		let productId=product.entityId;
		let name=product.name.replace(/\//g, "-");
		let subProductId=null;

		console.log(product);


		if(product.type=="Booking"){
			this.router.navigate([`/doctor-detail/${name.replace(/\s/g, "_")}/${this.ecryptServiceService.encrypt(productId)}`])
		}

	else if(product.configId!=null){
		productId=product.entityId;
		subProductId=product.configId;
		this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(productId)}
		/${this.ecryptServiceService.encryptionAES(subProductId)}/type`])	

	}else{
		this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(productId)}`])
	}
	
			
		}
		

		productNavigationUrl(product: any) {

			let productId=product.entityId;
			let name=product.name.replace(/\//g, "-");
			let subProductId=null;
		if(product.configId!=null){
			productId=product.entityId;
			subProductId=product.configId;
			this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(productId)}
			/${this.ecryptServiceService.encryptionAES(subProductId)}/type`])	
	
		}else{
			this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(productId)}`])
		}
		
				
			}
	
	

	productNavigationCart(product: any) {
		let productId=product.entityId;
		let subProductId=null;
	let name=product.name.replace(/\//g, "-");

      if(product.subChild==undefined){
		product.subChild=[]
	  }
	


		if(product.subChild.length>0){

			productId=product.subChild[0].productId;
			subProductId=product.productId;
			this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(productId)}
			/${this.ecryptServiceService.encryptionAES(subProductId)}/type`])	
		}
		if(productId==undefined){
			productId=product.productId;
			
			this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(productId)}`])
		}
	

	

	}

	productNavigations(product: any) {

		let productId=product.entityId;
	
		if(productId==undefined){
			productId=product.productId;
		}
	let name=product.name.replace(/\//g, "-");
			this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(productId)}/type`])
		}
	retrunProductUrl(product: any) {
		let productId=product.entityId;
		let name=product.name.replace(/\//g, "-");
		let subProductId=null;
		let finalValue=null;
	if(product.configId!=null){
		productId=product.entityId;
		subProductId=product.configId;
		finalValue =name.replace(/\s/g, "_")+'/product/'+this.ecryptServiceService.encrypt(productId)+'/'+this.ecryptServiceService.encryptionAES(subProductId)+'/type'
		}else{
			finalValue =name.replace(/\s/g, "_")+'/product/'+this.ecryptServiceService.encrypt(productId)

		}
		return finalValue	
		}

	productNavigationReload(url: any) {
      
		let value = this.router.url.split("/product");

           let nameEncrypted=value[1];
		   let nameBeforeUrl=value[0].split("/")

		   let   name=nameBeforeUrl[1];

		   name = name.replace(/\//g, "SLS");
		   name = name.replace(/"/g, "Por21Ld245");
	   
			   name = name.replace(/\%/g, 'xMl3Jk');
			   //name = name.replace(/”/g, '&');
			   //name = name.replace(/®/g, 'Ro');
			   name = name.replace(/\(/g, 'BRS');
			   name = name.replace(/\)/g, 'BREl3jk875');
	   
		   name = name.replace(/\+/g, 'plusAdd');
            
		let navigationExtras: NavigationExtras = {
            queryParams: {
                // "page":Constant.DEFAULT_PAGE_SIZE,
                "pid": nameEncrypted

            },

        };
		this.router.navigate([`/${name.replace(/\s/g, "_")}/product/${this.ecryptServiceService.encrypt(name)}`])
	}



	productSearch(name: any) {


		let navigationExtras: NavigationExtras = {
			queryParams: {
				// "page":Constant.DEFAULT_PAGE_SIZE,
				"sid": this.ecryptServiceService.encryptionAES(name),
			},

		};
		name = name.replace(/\//g, "FWDSLS")
		name = name.replace(/\s/g, "_");
		//name = name.replace(/”/g, '&');
		name = name.replace(/®/g, 'Ro');
		name = name.replace(/\(/g, 'BRS');
		name = name.replace(/\)/g, 'BREl3jk875');
		
		// name= name.replace(/"/g, "Por21Ld245");
		this.router.navigate([`search/${name}`], navigationExtras);
	}

	
	customSearch(name: any,header) {


		let navigationExtras: NavigationExtras = {
			queryParams: {
				// "page":Constant.DEFAULT_PAGE_SIZE,
				"sid": this.ecryptServiceService.encryptionAES(name),
			},

		};
		name = name.replace(/\//g, "FWDSLS")
		name = name.replace(/\s/g, "_");
		//name = name.replace(/”/g, '&');
		name = name.replace(/®/g, 'Ro');
		name = name.replace(/\(/g, 'BRS');
		name = name.replace(/\)/g, 'BREl3jk875');
		// name= name.replace(/"/g, "Por21Ld245");
		this.router.navigate([`custom/${header}`], navigationExtras);
		
	}

	selectedCategory(name: any, id: any,menu:any){
		if (menu.displayMode == "4") {
			this.router.navigate([menu.urlPath])
	
		} else if (menu.displayMode == "3") {
			let url = menu.urlPath;
	
			window.location.href = url
		} else {
	
			this.goToCategory(name,id)
		}
	}

	goToCategory(name: any, id: any) {


		
			name = name.toString().replace(/\//g, "")
		
	
		let navigationExtras: NavigationExtras = {
			queryParams: {
				// "page":Constant.DEFAULT_PAGE_SIZE,
				"sid": this.ecryptServiceService.encrypt(id)

			},

		};

		
		//this.googleAnalyticsService.emitEvent("testCategory1", "testAction", "testLabel", 10);
		this.googleAnalyticsService.event('event_category',name);


		this.router.navigate([`${name.toString().replace(/\s/g, "_")}/pr`], navigationExtras);
	}

	goToCategoryForOtherTypes(products,header) {

		header = header.replace(/\s/g, "-");
		products=JSON.stringify(products)
	  let data=this.ecryptServiceService.encryptionAES(products);
	  let navigationExtras: NavigationExtras = {
		queryParams: {
			// "page":Constant.DEFAULT_PAGE_SIZE,
			"pid": data

		},

	};

	this.router.navigate([`/products-list/${header}`], navigationExtras);
	}


	getEncryptedId(id: any): any {
		return this.ecryptServiceService.encrypt(id);
	  }

	  getValueForDecryptedData(data:any): any {
		return this.ecryptServiceService.decryptionAES(data);
	  }

	  getValueForDecryptedDataAes(data:any): any {
		return this.ecryptServiceService.encryptionAES(data);
	  }


	goToCategoryWithEncryptedKey(name: any, id: any) {


		let navigationExtras: NavigationExtras = {
			queryParams: {
				// "page":Constant.DEFAULT_PAGE_SIZE,
				"sid": id

			},

		};
		this.router.navigate([`${name.toString().replace(/\s/g, "_")}/pr`], navigationExtras);
	}


	getSliderData(Page: number, size: number, sort): Observable<Object> {
		return this.http.get(`${this.url}/catalog/config/slider/all?page=${Page}&size=${size}&sort=${sort}`);
	}

	getLayoutData(Page: number, size: number, sort): Observable<Object> {
		return this.http.get(`${this.url}/catalog/config/slider/advanced/all?page=${Page}&size=${size}&sort=${sort}`);
	}

	getTransaltedValue(value, locale, Page: any, size: any, sort: any): Observable<Object> {
		let url = environment.serverAPI + "/api/catalog";

		let payload = {
			"value": value,
			"locale": locale
		}
		return this.http.post(`${url}/translate?page=${Page}&size=${size}&sort=${sort}`, payload);

	}

	setLanguageLocale(localeTo, index) {
		sessionStorage.setItem(VALUE, localeTo);
		sessionStorage.setItem(LOCALEINDEX, index);
	}


	getLocaleIndex() {
		return sessionStorage.getItem(LOCALEINDEX);
	  }


	getTranslateCountry() {
		return sessionStorage.getItem(VALUE);
	  }


	  setCurrencyValue(value){
		localStorage.setItem(
			CURRENCY,
			value
		  );
	  }

	  getCurrencyValue(){
	   return localStorage.getItem(CURRENCY);
	  }

	getBannerLayout(): Observable<Object> {
		return this.http.get(`${this.url}/catalog/banner/discount`);
	}

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-left-menu-sub',
  templateUrl: './left-menu-sub.component.html',
  styleUrls: ['./left-menu-sub.component.scss']
})
export class LeftMenuSubComponent implements OnInit {

  constructor() { }

  @Input() category: any;

  ngOnInit(): void {
  }


}


<ng-container class="theme-container" *ngFor="let childrenSubItem of category">
   
    <div fxLayout="row">
        <a class="font"  [routerLink]="childrenSubItem.path" >
            {{childrenSubItem.categoryName}}
         </a>

    </div>
                            
    <ng-container *ngIf="childrenSubItem.subcategories.length>0"> 
     
    <div fxLayout="row">
    <calsoft-left-menu-sub 
    [category]="childrenSubItem.subcategories"></calsoft-left-menu-sub>


    </div>

    </ng-container>
 </ng-container>

                        
              
